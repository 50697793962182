body {
  background-repeat: no-repeat;
  background-position: 1000% 800%;
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
}

.hero-pic {
  margin: 10px auto;
}

.hero-pic img {
  max-width: 100%;
}

.mobileMenuHeader {
  display: flex;
  margin-top: 20px;
  align-items: center;
  align-content: center;
}

.mobile-links {
  display: block !important;
  text-align: left;
  margin: 8px 0 !important;
  width: 100%;
  text-indent: 24px;
}

.logo {
  margin-top: 16px;
  margin-left: 16px;
}

.menu-links {
  margin-top: 30px;
  justify-content: space-between;
}

.menu-links button,
.menu-links a {
  height: fit-content;
  padding: 10px;
  margin: 0 10px;
}

.how-conatiner {
  justify-content: center;
}

.how-conatiner img {
  max-width: 100%;
}

.pricing-section {
  background-image: url('img/seq.png');
  background-repeat: no-repeat;
  background-size: 90% 230px;
  background-position: 30% 130px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.pricing-section .paper {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    195deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(245, 248, 250, 1) 100%
  );
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
}

.footer {
  width: 100%;
  min-height: 100px;
  margin-top: 40px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 20%,
    rgba(246, 249, 251, 1) 20%,
    rgba(246, 249, 251, 1) 100%
  );
  padding-bottom: 60px;
}

.anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}

.footer-action {
  max-width: 1070px;
  background-image: url('img/seq.png');
  display: flex;
  justify-content: space-between;
  color: white;
  margin: 0 auto;
  background-size: 120% 120%;
  border-radius: 16px;
  overflow: hidden;
  min-height: 120px;
  padding: 20px 30px;
  margin-bottom: 24px;
  align-items: center;
}

.footer-action p {
  max-width: 400px;
  margin-left: 20px;
}

@media screen and (max-width: 900px) {
  .pricing-section {
    background-repeat: repeat-x;
    background-size: 110% 30%;
    background-position: 10% 10%;
  }

  .pricing-section .paper {
    margin: 0 auto;
  }

  .footer-action {
    display: block;
    margin-left: 20px;
    margin-right: 20px;
  }

  .footer-action p {
    margin-bottom: 16px;
  }

  .anchor {
    display: block;
    position: relative;
    top: -150px;
    visibility: hidden;
  }
}
